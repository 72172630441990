import { transporter } from '@services/loggers/http-transporter.service';

import { BytelAxiosRequestConfig } from '@app-types/axios';
import { HttpLoggerData, LogHttpTransporterInterface, LoggerContext } from '@app-types/logger';

import { AxiosError, AxiosResponse } from 'axios';

import { extractUrlPattern } from './patterns';

class AxiosLoggerService {
    private transporter: LogHttpTransporterInterface;

    public constructor(transporter: LogHttpTransporterInterface) {
        this.transporter = transporter;
    }
    public logHttpError(error: AxiosError) {
        const httpData: Partial<HttpLoggerData> = {
            status: error.response?.status || 0,
            output: JSON.stringify({
                message: error.message,
                description: error.response?.data,
            }),
        };

        this.sendLog(httpData, error.config as BytelAxiosRequestConfig);
    }

    public logHttpResponse(res: AxiosResponse) {
        const httpData: Partial<HttpLoggerData> = {
            status: res.status,
        };

        this.sendLog(httpData, res.config as BytelAxiosRequestConfig);
    }

    // @TODO: continue mutualize
    private sendLog(httpData: Partial<HttpLoggerData>, config: BytelAxiosRequestConfig): void {
        httpData.uri = config.url ?? '';
        httpData.input = config.data?.toString();
        httpData.method = config.method ?? '';
        httpData.duration = Date.now() - config.metadata.startDate;
        httpData.typeEv = 'APPELRESS';
        httpData.urlPattern = config.url ? extractUrlPattern(config.url) : '';
        httpData.ctx = this.createContext(httpData, config);
        if (config.headers) {
            httpData.headers = Object.fromEntries(
                Object.entries(config.headers).map(([key, value]) => [key, String(value)]),
            );
        }
        this.transporter.send(httpData as HttpLoggerData);
    }

    private createContext(httpData: Partial<HttpLoggerData>, config: BytelAxiosRequestConfig): LoggerContext {
        return {
            idPersonne: config?.user?.idPersonne,
            login: config.user?.login,
            userType: config.user?.userType,
        };
    }
}

export const axiosLoggerService = new AxiosLoggerService(transporter);
