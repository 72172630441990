const patterns: Array<{ path: RegExp; remplacement: string }> = [
    {
        path: /\/(0|\+33)[1-9]\d{8}(\/|$)/g,
        remplacement: '/{numTel}$2',
    },
    {
        path: /\/([a-z0-9][a-z0-9_\.-]*@[a-z][a-z0-9_\.-]*\.[a-z]+)/gi,
        remplacement: '/{email}',
    },
    {
        path: /\/\d+[TP]/g,
        remplacement: '/{ligne}',
    },
    {
        path: /\/produits\/[A-Z0-9_]+/g,
        remplacement: '/produits/{produit}',
    },
    {
        path: /\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gi,
        remplacement: '/{id}',
    },
    {
        path: /\/(\d+)/g,
        remplacement: '/{id}',
    },
];

export function extractUrlPattern(url: string): string {
    url = url.replace(/\?.+/, '');
    patterns.forEach(({ path, remplacement }) => {
        url = url.replace(path, remplacement);
    });
    return url;
}
